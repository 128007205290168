html, body {
    background-color: #FAF0CA;
}

nav {
    background-color: #0D3B66;
}

.navbar-brand {
    color: #FAF0CA;
    margin-left: 16px;
}

main {
    padding-bottom: 90px;
}

p {
    font-size: 19px;
    font-family: "Lucida Console";
}

.container h1 {
    color: #849698;
    font-size: 40px;
}

footer {
    position: fixed;
    bottom: 0;
    background-color: #0D3B66;
    width: 100%;
    text-align: center;
}

footer h3{
    color: #FAF0CA;
    vertical-align: middle;
    line-height: 50px;
    margin-bottom: 0;
}

footer ul {
    margin-bottom: 5px;
    list-style-type: none;
    padding-left: 0;
}
footer ul li {
    margin-top: 2.625px;
    display: inline;
    padding-right: 5px;
}

footer a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #F95738;
    transition: 0.5s;
    transform: scale(0.9);
    z-index: -1;
  }

footer a:hover::before {
    transform: scale(1.05);
}

@media (min-width: 768px){
    footer h3 {
        line-height: 90px;
    }
    footer ul {
        text-align: start;
        margin-top: 31.75px;
        margin-bottom: 31.75px;
    }
    p {
        font-size: 30px;
    }
}